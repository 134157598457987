/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-10-10 09:50:36
 * @LastEditors: 杨慢慢
 * @LastEditTime: 2024-01-25 10:22:13
 * @FilePath: \yda_web_manage\src\apis\deviceManage.js
 * @Description: 终端管理接口
 */

import request from '@/utils/request'

// 获取终端管理列表
export function getDeviceList(params) {
  return request({
    url: `/pc/equipment/manage/list`,
    method: 'GET',
    params
  })
}

//获取终端详情
export function getDeviceDetail(params) {
  return request({
    url: `/pc/equipment/manage/detail`,
    method: 'GET',
    params
  })
}

//高拍仪用印环境拍摄设置
export function setDeviceFace(equipmentId, deviceFace) {
  return request({
    url: `/pc/equipment/manage/settingDeviceFace/${equipmentId}/${deviceFace}`,
    method: 'PUT'
  })
}

//印控仪未识别到设备关仓时间设置
export function setGateTime(equipmentId, gateTime) {
  return request({
    url: `/pc/equipment/manage/settingGateTime/${equipmentId}/${gateTime}`,
    method: 'PUT'
  })
}

//天玺高拍仪模式设置
export function setPhotographType(equipmentId, photographType) {
  return request({
    url: `/pc/equipment/manage/settingHighCamera/${equipmentId}/${photographType}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//设备远程唤醒设置
export function settingRemoteWake(equipmentId, remoteWake) {
  return request({
    url: `/pc/equipment/manage/settingRemoteWake/${equipmentId}/${remoteWake}`,
    method: 'PUT',
    // headers: {
    //   'Content-Type': 'application/json'
    // }
  })
}

//天玺待机时长设置
export function setAwaitTime(equipmentId, offTime) {
  return request({
    url: `/pc/equipment/manage/settingOffTime/${equipmentId}/${offTime}`,
    method: 'PUT'
  })
}
//天玺休眠时长设置
export function setDormancyTime(equipmentId, sleepTime) {
  return request({
    url: `pc/equipment/manage/settingSleepTime/${equipmentId}/${sleepTime}`,
    method: 'PUT'
  })
}

// aplus 灵敏度设置
export function settingQyroParameter(equipmentId, qyroParameter) {
  return request({
    url: `pc/equipment/manage/settingQyroParameter/${equipmentId}/${qyroParameter}`,
    method: 'PUT'
  })
}
//特权连续用印模式
export function setContinuousPrinting(params) {
  return request({
    url: `/pc/equipment/manage/seal/pattern`,
    method: 'PUT',
    params
  })
}
//语音播报
export function setVoiceBroadcast(params) {
  return request({
    url: `/pc/equipment/manage/video`,
    method: 'PUT',
    params
  })
}
